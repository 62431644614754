import React, { useContext } from "react";
import { FeaturedListingMainContainer } from "./style";
import { Link, navigate } from "gatsby";
import Button from "../../Button/Button";
import ModalContext from "../../context/ModalContext";
interface HowToBuyProps {
  getMapData: {
    img?: string;
    card_title?: string;
    card_title_tag?: string;
    btn_val?: string;
    remove?: string;
    step?: string;
    url?: string
  }[];
  howToBuyHeading?: JSX.Element;
  lineHeight?: number;
}

const HowToBuy: React.FC<HowToBuyProps> = ({
  getMapData,
  howToBuyHeading,
  lineHeight,
}) => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);

  const handleBtnClick = (value: any) => {
    if (value?.btn_val === "Get My VALUATION") {
      setModalvisible(true);
      setPopupContent("homeValuation");
    }
  };
  return (
    <>
      <FeaturedListingMainContainer
        lineHeight={lineHeight}
        className="py-[62px] bg-[#fff] sm:pt-[32px] sm:pb-[71px]"
      >
        <div className="container">
          {howToBuyHeading}
          <div className="after_cus pt-[57px] flex flex-wrap relative bg-[#fff] sm:pt-[30px]">
            {getMapData?.map((value) => {
              return (
                <>
                  <div className="even_cus_css w-[100%] flex items-center relative 2xs:flex-wrap">
                    <div className="images w-[50%] px-[20px] py-[20px] 2xs:w-[100%] 2xs:!px-[0px]">
                      <img
                        className="rounded-[60px] w-[80%] m-[auto] xs:w-[100%]"
                        src={value.img}
                        alt="howToBuyImage"
                      />
                    </div>
                    <div className="featured_listing_card_descrip pt-[20px] w-[50%] 2xs:w-[100%]">
                      <div className="card_title">
                        <h3 className="font-Quicksand text-d-30 text-[#1A1A1A] font-[500] text-left mb-[16px]">
                          {value.step} -{" "}
                          <span className="font-IbarraRealNova font-[700]">
                            {value.card_title}
                          </span>
                        </h3>
                        <p className=" font-Quicksand text-xs font-normal mb-[16px]">
                          {value.card_title_tag}
                        </p>
                        {
                          value.url ?
                            <a href={value.url} className="bg-black text-white py-12 px-[24px] text-d-3lg font-[600] rounded-2 border border-black hover:text-black hover:bg-white hover:border hover:border-black font-Quicksand uppercase" target="_blank" download>{value.btn_val}</a>
                          :
                        <Link to="#">
                          <Button
                            onClick={() => handleBtnClick(value)}
                            type="solid"
                            id={value.remove}
                            customStyle="px-[24px]"
                            >
                            <p>{value.btn_val}</p>
                          </Button>
                        </Link>
                          }
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </FeaturedListingMainContainer>
    </>
  );
};

export default HowToBuy;
