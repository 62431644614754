import styled from 'styled-components'
interface featureListProps {
  lineHeight?: number;
}
const FeaturedListingMainContainer = styled.div<featureListProps>`
  & .even_cus_css::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #006bce;
    height: 16px;
    width: 16px;
    border-radius: 25px;
    z-index: 1;
    @media (max-width: 575px) {
      display: none;
    }
  }
  & .images {
    width: 50%;
    padding: 20px;
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  & .after_cus::after {
    content: '';
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #b2b2b2;
    height: ${(props) => props.lineHeight || 82}%;
    width: 1px;
    @media (max-width: 575px) {
      display: none;
    }

    /* tailwind css */
    /* after:content[*] after:absolute after:mx-[auto] after:!my-[auto] after:top-[45px] bottom-[0px] after:left-[0px] after:right-[0px] after:bg-[#b2b2b2] after:h-[82%] after:w-[1px]F */
  }

  .even_cus_css:nth-child(even) .images {
    order: 2;
    @media (max-width: 575px) {
      order: 1;
    }
  }
  @media (max-width: 575px) {
    .even_cus_css:nth-child(even) .featured_listing_card_descrip {
      order: 2;
    }
  }

  & .featured_listing_card_descrip .card_title {
    text-align: left;
    padding-bottom: 30px;
    width: 78%;
    margin: auto;
    @media (max-width: 479px) {
      width: 100%;
    }
  }

  & .featured_listing_card_descrip .card_title .text-xs {
    font-weight: 500 !important;
    color: #666666;
  }

  & .featured_listing_card_descrip .card_title h4 {
    font-size: 30px;
    font-weight: 700;
    line-height: 37.5px;
    margin-bottom: 16px;
  }

  & .featured_listing_card_descrip .feature_listing_card_info ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .featured_listing_card_descrip .feature_listing_card_info ul li {
    padding: 6px;
    border: 1px solid grey;
    width: 100%;
    border-bottom: 0;
    max-width: calc(100% / 4);
  }

  &
    .featured_listing_card_descrip
    .feature_listing_card_info
    ul
    li:first-child {
    border-left: 0;
  }

  & .featured_listing_card_descrip .feature_listing_card_info ul li {
    border-right: 0;
  }

  & .featured_listing_card_descrip .feature_listing_card_info ul .title p {
    font-size: 13px;
    text-align: center;
    font-weight: 800;
  }

  & .featured_listing_card_descrip .feature_listing_card_info ul .title_info {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
  }
`

export { FeaturedListingMainContainer }
