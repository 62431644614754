import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import SellHome from "../component/UniversityHeights/Sell";

const Sell = () => {
  return (
    <LayoutWrapper
      title="Sell My Home in University Heights | Realtors | Selby Team"
      desc="Find out your home's value in University Heights from experienced realtors. When you’re ready to sell, we’ll help you with the listing & finding a great buyer."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <SellHome />
    </LayoutWrapper>
  );
};

export default Sell;
